import React from "react";
import { motion } from "framer-motion";

import loadable from "@loadable/component";
const ImageLoader = loadable(() => import("../common/ImageLoader"));
import { useIsMobile } from "../../hooks";

import sevenFilters from "assets/images/landingAir/mila-filters/mila-filters-boxes.png";
import sevenFiltersWEBP from "assets/images/landingAir/mila-filters/mila-filters-boxes.png?as=webp";

/**
 * @returns {JSX.Element}
 * @constructor
 */

const AllMilaFilters = () => {
  const isMobile = useIsMobile(769);
  return (
    <section className="mila-filters">
      <div className="mila-filters__card">
        <div className="container">
          <div className="mila-filters__header">
            <motion.h2
              className="mila-filters__title"
              initial={{ opacity: 0 }}
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.6, delay: 0 }}
              variants={{
                visible: { opacity: 1 },
                hidden: { opacity: 0 },
              }}
            >
              Seven personalized filters.
            </motion.h2>
            <motion.div
              className="mila-filters__description"
              initial={{ opacity: 0 }}
              whileInView="visible"
              viewport={{ once: true }}
              transition={{ duration: 0.6, delay: 0.02 }}
              variants={{
                visible: { opacity: 1 },
                hidden: { opacity: 0 },
              }}
            >
              <p>
                Traditional air purifiers have one generic filter that tries to
                do a little bit of everything (and end up doing nothing well).
                Mila offers unique filter options to address the specific needs
                air breathers face.
              </p>
            </motion.div>
          </div>
          <div className="mila-filters__media">
            <ImageLoader
              className="mila-filters__image img-relative"
              placeholderAspectRatio={1390 / 571}
              image={sevenFilters}
              webp={sevenFiltersWEBP}
              alt={"Seven personalized filters"}
            />
          </div>
        </div>
      </div>
    </section>
  );
};
export default AllMilaFilters;
